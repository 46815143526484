<template>
  <section>
      <div class="content-header">
        <h2>What is resilient course design? </h2>
      </div>
      <div class="content-wrapper">
        <p>Did you know that how you design your course can contribute to students’ resilience? Consider reviewing <a href="https://uwaterloo.ca/centre-for-teaching-excellence/teaching-resources/teaching-tips/planning-courses-and-assignments/course-design/resilient-course-design" target="_blank">Resilient Course Design</a> from the University of Waterloo and <a href="https://taylorinstitute.ucalgary.ca/news/promoting-reflection-and-resilience-through-course-design" target="_blank">Promoting Reflection and Resilience through Course Design</a> from the University of Calgary as you review your own.  </p>
        <h3>Resilient <em>online</em> course design </h3>
        <p>Academically resilient teaching can occur in online spaces as well. Review <a href="https://docs.google.com/document/d/1bKI37aRCTKrBy25eyM04yNTtqW8uU1xorQAlY1zjm6Q/edit" target="_blank">Re-envisioning Classroom Activities in the Online Environment</a> for activities and strategies to bolster academic resilience in virtual environments.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
